<template>
    <div
        style="-webkit-appearance: none;-moz-appearance: none;appearance: none;opacity: 1"
        v-bind:disabled="locked"
        @click="addPick(sport_id,match_id,market_name,market_id,specifier,status,home_team,away_team,pick)"
        v-bind:class="statusClass">

      <div>
        <span class="outcome-name-text" v-text="alias"></span>
        <span v-if="!locked" class="odd-direction" v-bind:class="directionClass"></span>
      </div>

      <svg v-show="isHot" data-v-75c745e2="" data-v-fe710888="" class="svg-icon icon-hot"><!---->
        <use data-v-75c745e2="" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/assets/images/fire.svg#icon-hot"></use>
      </svg>

      <span
          v-if="!locked"
          v-bind:id="id"
          v-bind:oddStatus="oddStatus"
          v-bind:oddValue="odd"
          v-bind:oddActive="oddActive"
          v-bind:producerId="producer_id"
          v-bind:producerStatus="producerStatus"
          class="odd-value">

        <span v-if="locked">-</span>
        <span v-else>{{ odd }}</span>

      </span>
      <span v-if="locked" style="height: 1.5em;">
        <LockIcon :size="iconSize" fill-color="grey"></LockIcon>
      </span>

    </div>

</template>

<script>
const LockIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Lock.vue');

export default {
  name: "Odd",
  props: {
    pick: {
      required: true
    },
    away_team: {
      required: true
    },
    home_team: {
      required: true
    },
    market_name: {
      required: true
    },
    sport_id: {
      required: true
    },
    show_direction: {
      required: false,
      default: true
    },
    previous_odds: {
      required: true
    },
    odds: {
      required: true
    },
    probability: {
      required: false,
      default: 0
    },
    match_id: {
      required: true
    },
    market_id: {
      required: true
    },
    outcome_id: {
      required: true
    },
    specifier: {
      required: true
    },
    active: {
      required: true
    },
    status: {
      required: true
    },
    producer_id: {
      required: true
    },
    producer_status: {
      required: true
    },
    alias: {
      required: true,
      default: '',
    },
    boosted: {
      required: false,
      default: false,
    }
  },
  methods: {
    oddsChange: function(payload){

      var vm = this;

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if(parseFloat(payload.odds) !== parseFloat(vm.odds)) {

        vm.previousOdds = vm.odd;
        vm.odd = payload.odds;
      }

      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();

    },
    getOddDirectionClass: function () {

      if(parseInt(this.producerStatus) === 0 || parseInt(this.oddActive) === 0 || parseInt(this.oddStatus) !== 0) {

        return '';

      }

      if(parseFloat(this.odd) >= parseFloat(this.previousOdds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },
    getStatusClass: function (){

      this.statusClass = this.locked ? 'lock' : '';

    }
  },
  mounted: function () {

    this.odd = this.odds;
    this.oddStatus = this.status;
    this.oddActive = this.active;
    this.previousOdds = this.previous_odds;
    this.producerStatus = this.producer_status;

    if(parseInt(this.producer_id) === 1 ) {

      this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0 || parseInt(this.producerStatus) === 0;

    } else {

      this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;

    }

    this.getStatusClass();

    this.id = this.getOddID(this.match_id,this.market_id,this.outcome_id,this.specifier,this.boosted ? 'boosted' : 'odd');
    this.directionClass = this.getOddDirectionClass();
    var vm = this;

    this.EventBus.$on('odds:'+vm.id,function(payload){

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();

    });

    this.EventBus.$on('producer:status',function(payload){

      var producer_id = payload.producer_id;
      var producer_status = payload.producer_status;

      if(parseInt(producer_id) === 1 ) {

        vm.producerStatus = producer_status;
        vm.directionClass = vm.getOddDirectionClass();
        vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(producer_status) === 0;
        vm.getStatusClass();
      }

    });

    this.EventBus.$on('betstop:match-:'+vm.match_id,function(){

      vm.oddStatus = 1; // deactivate
      vm.locked = true;
      vm.getStatusClass();

    });

    this.EventBus.$on('status:match-:'+vm.match_id+':market-'+vm.market_id,function(payload){

      vm.oddStatus = payload.status; // deactivate
      vm.locked = parseInt(vm.oddStatus) > 0;
      vm.getStatusClass();

    });


  },
  data: function (){

    return {
      iconSize: 20,
      id: '',
      odd: '',
      directionClass: '',
      oddStatus: '',
      oddActive:'',
      previousOdds:'',
      producerStatus: '',
      locked: false,
      statusClass:'',
    }
  },
  computed: {

    isHot: function (){

      return parseFloat(this.probability) > 0.65
    }

  },
  components: {
    LockIcon
  }
}
</script>