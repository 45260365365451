<template>
  <div class="container">
    <div class="row">
      <div class="col-12 popular-games-header">
        <h4><strong>Boosted Odds</strong></h4>
      </div>
    </div>

    <div class="row flex-row flex-nowrap" style="overflow-x: auto;scrollbar-width: none;-ms-overflow-style: none;">

      <div class="popular-games col-9" v-for="f in highlightsRawData" v-bind:key="getKey(f.match_id)">
        <div class="row">

          <div class="col-8 text-left popular-games-competition-name">
            <span v-text="f.country"></span> / <span class="text-capitalize" v-text="f.tournament"></span>
          </div>

          <div class="col-4 text-right popular-games-competition-name">
            {{ formatMatchDate(f.date) }}
          </div>

          <div class="col-5">
            <div class="row">

              <div class="col-8 text-right">
                <strong class="popular-games-team-name" v-text="f.home_team"></strong>
              </div>

              <div class="col-4 text-left" style="padding-left: 10px">
                <img v-bind:src="f.competitor1_flag" v-bind:height="iconSize">
              </div>

            </div>

          </div>

          <div class="col-2 text-center">
            -
          </div>

          <div class="col-5">

            <div class="row">
              <div class="col-4 text-right" style="padding-right:10px">
                <img v-bind:src="f.competitor2_flag" v-bind:height="iconSize">
              </div>

              <div class="col-8 text-left">
                <strong class="popular-games-team-name" v-text="f.away_team"></strong>
              </div>

            </div>

          </div>

          <div
              v-bind:id="getID(o.odd_id)"
              v-for="o in f.outcomes"
              v-bind:key="getKey(o.odd_id)"
              class="col text-left bold"
              v-bind:class="getClasses(o)"
              style="font-size: .8rem;line-height: 1.6em;">

            <Odd
                class="text-center"
                v-bind:sport_id="f.sport_id"
                v-bind:match_id="f.match_id"
                v-bind:market_name="o.market_name"
                v-bind:home_team="f.home_team"
                v-bind:away_team="f.away_team"
                v-bind:market_id="o.market_id"
                v-bind:outcome_id="o.outcome_id"
                v-bind:specifier="o.specifier"
                v-bind:active="o.active"
                v-bind:status="o.status"
                v-bind:odds="o.odd"
                v-bind:producer_id="o.producer_id"
                v-bind:producer_status="o.producer_status"
                v-bind:previous_odds="o.previous_odds"
                v-bind:alias="o.alias"
                v-bind:probability="o.probability"
                boosted=true
                v-bind:pick="o"/>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
  .icon-new.red {
    color: #fff;
    background-color: red;
  }
  .icon-new {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 8px;
    background: #fff30a;
    background-color: rgb(255, 243, 10);
    padding: 2px 4px;
    color: red;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 700;
    z-index: 702;
  }
</style>
<script>

import Odd from './Odd'
import fixtures from "@/services/fixtures";

export default {
  name: "Boosted",
  methods: {
    getID: function(id){

      return 'boosted-'+id;

    },

    getPopularGames: function () {

      var vm = this;

      var path = "/highlights/{sport_id}"// process.env.VUE_APP_URL_HIGHLIGHTS_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {

        vm.is_busy = false;
        console.log('Current page '+vm.page+' but reached end of page ');
        return;

      }

      if(vm.is_busy) {

        console.log('Current page '+vm.page+' but is busy ');
        return;

      }

      console.log('Current page '+vm.page);
      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy',true);

      let payload = {
        upcoming: 0,
        boosted: 1,
        today: 0,
      }

      fixtures.get(path, {params: payload})
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy',false);

            vm.highlightsRawData = res.data.data;

            console.log("highlightsRawData.length === "+vm.highlightsRawData.length);


          })

          .catch(err => {

            vm.EventBus.$emit('event:busy',false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getClasses: function (o) {

      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';

      return "btn-odd small-txt mr-1 mt-1" +
          " match-" + o.match_id +
          " producer-" + o.producer_id +
          " market-" + o.market_id +
          " outcome-" + o.outcome_id +
          " specifier-" + o.specifier;
    },

    getKey: function(match_id) {

      var prefix = "popular-games-"+match_id;
      return Math.random().toString(10).replace('0.', 'odd-id-'+prefix + '-');

    },
    setSport: function (sportID) {

        this.$store.dispatch("setSportID",sportID);

        if(this.$store.state.current_page === 'home')
          return

        this.$router.push({name: 'home'});
    },
    getMenuTextClass: function (sportID){

      return this.current_page === "esport" || this.current_page === "freebet" ? "" :  parseInt(this.$store.state.sport_id) === parseInt(sportID) ? "menu-active" : "";

    },
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
    resetGames: function(){

      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

        this.goHome();

      //}

    }
  },
  computed: {
    sportIcon: function () {

      return this.getSportIcon(this.sport_id);

    },
    iconSize: function (){

      return 24;

    },
    current_page: function () {

      return this.$store.state.current_page
    },
    show_freebet: function (){

      var p = this.getProfile();
      if(!p) {

        return true;

      }

      return parseInt(p.b4) > 0;

    },
    sports: function() {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s,function(k,v){

        if(v.sport_id > 1 ) {

          t.push(v);
        }

      })

      return t;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
  },
  components: {
    Odd
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if(parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      this.getPopularGames();

    },
  },
  data: function() {
    return {
      highlightsRawData:[]
    }
  },
  mounted: function () {

    this.getPopularGames();

  }
  }
</script>